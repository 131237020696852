<template>
  <div class="row">
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" :color="color" loader="dots" />
    <div class="col-12 f13">
      <div class="card concard card-shadow">
        <div class="card-header white text-center">
          <img src="@/assets/logo.png" style="height: 100px" alt="..." />
          <div class="t1" style="margin: 5px">ลงทะเบียนผู้ใช้งาน</div>
        </div>
        <Form @submit="onSubmitRegister" :validation-schema="register" id="kt_account_profile_details_form"
          ref="formContainer" novalidate="novalidate">
          <div class="card-body mb-47 mt-2 white">
            <!--begin::Card body-->
            <div class="card-body">
              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">คำนำหน้า</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field as="select" name="fn" class="form-control  form-control-solid" @change="checkFn($event)"
                    v-model="data_register.fn">
                    <option value="" selected disabled>โปรดเลือก</option>
                    <option value="นาย">นาย</option>
                    <option value="นาง">นาง</option>
                    <option value="นางสาว">นางสาว</option>
                    <option value="อื่นๆ">อื่นๆ</option>
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="fn" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row mt-1" v-if="fn_status">
                  <Field type="text" name="fn_other" class="form-control  form-control-solid" placeholder="คำนำหน้า"
                    v-model="data_register.fn_other" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="fn_other" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">ชื่อ</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field type="text" name="firstname" class="form-control  form-control-solid" placeholder="ชื่อ"
                    v-model="data_register.firstname" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="firstname" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">นามสกุล</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field type="text" name="lastname" class="form-control  form-control-solid" placeholder="นามสกุล"
                    v-model="data_register.lastname" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="lastname" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">เพศ</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <div class="row mt-2">
                    <div class="col-6">
                      <label class="form-check form-check-custom form-check-solid me-9">
                        <Field type="radio" name="gender" class="form-check-input" value="ชาย"
                          v-model="data_register.gender" />
                        <span class="form-check-label gender" for="kt_roles_select_all">ชาย <img :src="iconMale"
                            class="icon" alt="" /></span>
                      </label>
                    </div>
                    <div class="col-6">
                      <label class="form-check form-check-custom form-check-solid me-9">
                        <Field type="radio" name="gender" class="form-check-input" value="หญิง"
                          v-model="data_register.gender" />

                        <span class="form-check-label gender" for="kt_roles_select_all">หญิง <img :src="iconFemale"
                            class="icon" alt="" /></span>
                      </label>
                    </div>
                  </div>
                  <div class="fv-plugins-message-container mt-3">
                    <div class="fv-help-block">
                      <ErrorMessage name="gender" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->




              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">ช่วงอายุ</label>


                <Field type="hidden" name="age_range" v-model="data_register.age_range" />

                <div class="p-2 flex-grow-1 text-center">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="age_range" value="<20"
                      v-model="data_register.age_range" />
                    <h6>ต่ำกว่า 20</h6>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="age_range" value="20-35"
                      v-model="data_register.age_range" />
                    <h6>20 - 35</h6>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="age_range" value="36-50"
                      v-model="data_register.age_range" />
                    <h6>36 - 50</h6>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="age_range" value=">50"
                      v-model="data_register.age_range" />
                    <h6>มากกว่า 50</h6>
                  </div>
                </div>


                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="age_range" />
                  </div>
                </div>
                <!--end::Label-->

                <!--begin::Col-->

                <!--end::Col-->
              </div>

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">เบอร์โทรศัพท์</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field type="text" name="tel" class="form-control  form-control-solid" placeholder="เบอร์โทรศัพท์"
                    v-model="data_register.tel" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="tel" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Card body-->
          </div>
          <div class="card-footer white">
            <div class="d-flex justify-center align-center">
              <button type="submit" class="button">ลงทะเบียน</button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import iconMale from "@/assets/icon/Male.png";
import iconFemale from "@/assets/icon/Female.png";
import { defineComponent, ref, onMounted, inject } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import { ErrorMessage, Field, Form } from "vee-validate";
import useRegister from "@/service/api/register";
import useSweetalert from "@/service/sweetalert2";
import liff from "@line/liff";
import * as Yup from "yup";
export default defineComponent({
  name: "Register",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading
  },
  setup() {
    let color = ref(process.env.VUE_APP_COLOR);
    let isLoading = ref(false);
    const { Register } = useRegister();
    const { Sconfirm, SconfirmNc } = useSweetalert();
    const store = inject("store");
    const router = inject("router");
    let fn_status = ref(false);
    const data_register = store.getters.getRegister;
    const phoneRegExp =
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const register = Yup.object().shape({
      fn: Yup.string().required("กรุณาเลือก คำนำหน้า").label("คำนำหน้า"),
      fn_other: Yup.string()
        .when("fn", {
          is: (fn) => fn === "อื่นๆ",
          then: Yup.string().required("กรุณากรอก คำนำหน้า"),
        })
        .label("คำนำหน้า"),
      firstname: Yup.string().required("กรุณากรอก ชื่อ").label("ชื่อ"),
      lastname: Yup.string().required("กรุณากรอก นามสกุล").label("นามสกุล"),
      gender: Yup.string().required("กรุณาเลือก เพศ").label("เพศ"),
      tel: Yup.string()
        .required("กรุณากรอกเบอร์โทรศัพท์")
        .matches(phoneRegExp, "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง")
        .min(10, "เบอร์โทรศัพท์ไม่ครบ")
        .max(10, "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง"),
      age_range: Yup.string().required("กรุณาเลือกช่วงอายุ"),

    });

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const onSubmitRegister = (values) => {
      store.dispatch(SET_REGISTER, values);



      Sconfirm("ยืนยัน การลงทะเบียน", "question").then(async (result) => {
        if (result.isConfirmed) {
          isLoading.value = true;
          await Register(store.getters.getRegister)
            .then((result) => {
              isLoading.value = false;
              if (result.data.status === "success") {
                SconfirmNc("ลงทะเบียนสำเร็จ", "success", "ตกลง").then(() => {
                  if (store.getters.getUrlCallBack.id) {
                    liff.ready.then(() => {
                      liff.openWindow({
                        url: store.getters.getUrlCallBack.id,
                        external: false,
                      });
                    });
                  } else {
                    liff.ready.then(() => {
                      liff.closeWindow();
                    });
                  }
                });
              } else {
                SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(() => {
                  liff.ready.then(() => {
                    liff.closeWindow();
                  });
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });




      // router.push({ name: "Register_2" });
    };

    function checkFn(event) {
      if (event.target.value === "อื่นๆ") {
        fn_status.value = true;
      } else {
        fn_status.value = false;
      }
    }

    return {
      onSubmitRegister,
      register,
      checkFn,
      fn_status,
      iconMale,
      iconFemale,
      data_register,
      color,
    };
  },
});
</script>

<style scoped>
.bdhd {
  border-bottom: 2px solid #dee2e6 !important;
}

.icon {
  width: 50px;
  padding: 5px;
}

.gender {
  padding: 30px 20px;
  border: 1px solid #dee2e6;
  border-radius: 10px;
}

input:checked+span {
  color: var(--color);
  border: 2px solid var(--color);
}
</style>
